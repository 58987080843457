import React, { useState } from 'react';
import './App.css';
import {
    Button,
    Card,
    Container, Form, FormCheck, FormControl, FormGroup, FormLabel, FormSelect,
} from 'react-bootstrap';

enum ReleaseTypes {
    SINGLE = 'Single',
    EP = 'EP',
    ALBUM = 'Album',
}

type ReleaseTypeKey = keyof typeof ReleaseTypes;

class FormData {
    trackName: string;

    explicit: boolean;

    type: ReleaseTypes;

    lyrics: string;

    constructor(data?: Partial<FormData>) {
        this.trackName = data?.trackName || '';
        this.explicit = data?.explicit || false;
        this.type = data?.type || ReleaseTypes.SINGLE;
        this.lyrics = data?.lyrics || '';
    }
}

function App() {
    const [data, setData] = useState<FormData>(new FormData());
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const ReleaseTypeOptions = Object.keys(ReleaseTypes);
    return (
        <Container className="pt-4">
            {showConfirmation ? (
                <>
                    <h1 className="mb-4">Thanks for submitting!</h1>
                    <p>Your submitted data is below.</p>
                    {Object.keys(data).map((key) => (
                        <Card className="mb-4">
                            <Card.Body>
                                <Card.Title>{key.toUpperCase()}</Card.Title>
                                <pre className="mb-0">{`${data[key as keyof FormData]}`}</pre>
                            </Card.Body>
                        </Card>
                    ))}
                </>
            ) : (
                <>
                    <h1 className="mb-4">Submit a release for playlist</h1>
                    <Form>
                        <legend className="mb-3 pb-2 border-bottom">Use this form to submit a release for consideration on our playlist.</legend>
                        <FormGroup key="trackNameInputGroup" className="mb-4">
                            <FormLabel htmlFor="trackName">Track name</FormLabel>
                            <FormControl
                                id="trackName"
                                value={data.trackName}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        trackName: e.currentTarget.value,
                                    });
                                }}
                                type="text"
                                placeholder="Happier Than Ever"
                            />
                        </FormGroup>
                        <FormGroup key="explicitInputGroup" className="mb-4">
                            <FormLabel htmlFor="explicit">Is this release explicit?</FormLabel>
                            <FormCheck
                                id="explicit"
                                checked={data.explicit}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        explicit: e.currentTarget.checked,
                                    });
                                }}
                            />
                        </FormGroup>
                        <FormGroup key="typeInputGroup" className="mb-4">
                            <FormLabel htmlFor="releaseType">Release type</FormLabel>
                            <FormSelect
                                id="releaseType"
                                value={Object.keys(ReleaseTypes)[Object.values(ReleaseTypes).indexOf(data.type)]}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        type: ReleaseTypes[e.currentTarget.value as ReleaseTypeKey],
                                    });
                                }}
                            >
                                {ReleaseTypeOptions.map((v: string) => (
                                    <option key={`typeOption-${v}`} value={v}>{ReleaseTypes[v as ReleaseTypeKey]}</option>
                                ))}
                            </FormSelect>
                        </FormGroup>
                        <FormGroup key="lyricsInputGroup" className="mb-4">
                            <FormLabel htmlFor="lyrics">Lyrics</FormLabel>
                            <FormControl
                                id="lyrics"
                                as="textarea"
                                value={data.lyrics}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        lyrics: e.currentTarget.value,
                                    });
                                }}
                            />
                        </FormGroup>
                        <Button
                            className="me-2"
                            type="button"
                            variant="outline-dark"
                            onClick={(e) => {
                                e.preventDefault();
                                setData(new FormData());
                            }}
                        >
                            Clear
                        </Button>
                        <Button
                            type="submit"
                            variant="dark"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowConfirmation(true);
                            }}
                        >
                            Submit
                        </Button>
                    </Form>
                </>
            )}
        </Container>
    );
}

export default App;
